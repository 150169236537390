.article-body p {
  margin-top: 20px;
  width: 100%;
}

.article-body li {
  list-style: inside;
}

.article-body ul {
  margin-top: 10px;
}

.article-body {
    display: flex;
    flex-direction: column;
}

.article-body a {
    text-decoration: underline;
}

.article-body .imgParent {
  width: 100%;
  height: 400px;  
  display: flex;
  justify-content: center;
}

.article-body .imgParent img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  align-self: center;
}
